
export default {
  name: 'Accordion',
  props: {
    imgUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    paragraph: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isClicked: false,
    };
  },
  methods: {
    openAccordion() {
      this.isClicked = !this.isClicked;
    },
  },
};
