import { tracker } from '@/tools';
import inappTools from '@/common/inappTools';

const promotionMixins = {
  methods: {
    trackingUserAction(name) {
      if (inappTools.isInApp()) {
        // 인 앱환경에서만 트래킹을 추가한다고 합니다.
        tracker.recordUserAction(name);
      }
    },
  },
  computed: {
    priceComma() {
      return (price: string | number): string | 0 => {
        const changedToInt = typeof price === 'string' ? parseInt(price, 10) : price;
        const priceResult = changedToInt ? changedToInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
        return priceResult;
      };
    },

    // 서버에서 오는 month_cnt 를 조건에 맞게 변경
    monthCnt() {
      return (idx) => {
        switch (idx) {
          case 7:
            return { originMonth: 6, bonus: 1 } as { originMonth: number; bonus: number };

          case 14:
            return { originMonth: 12, bonus: 2 } as { originMonth: number; bonus: number };

          default:
            return { originMonth: 12, bonus: 2 } as { originMonth: number; bonus: number };
        }
      };
    },
    isDueDateEnd() {
      return (timestampEnd): boolean => new Date().getTime() >= timestampEnd;
    },
  },
};

export default promotionMixins;
