export default {
  // directive definition
  mounted(el) {
    function loadImage() {
      const isImg = el.nodeName === 'IMG';
      // 이미지 태그일 경우만 url 입력 로딩
      if (isImg) {
        el.setAttribute('src', el.dataset.url);
      }
    }

    function createObserver() {
      const intersectionObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 감지대상이 교차영역에 진입 할 경우
            loadImage(); // 교차영역 들어올경우 이미지 로딩
            observer.unobserve(el); // 이미지 로딩 이후론 관찰할 필요 x
          }
        });
      });

      intersectionObserver.observe(el);
    }

    // 지원하지 않는 브라우저는 바로 이미지 로딩을 시켜주도록 호환성 체크
    if (window.IntersectionObserver) {
      createObserver();
    } else {
      loadImage();
    }
  },
};
