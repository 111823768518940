import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c2d2277a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "accordion__content" }
const _hoisted_3 = {
  key: 0,
  class: "accordion__content__paragraph"
}
const _hoisted_4 = { class: "accordion__content__paragraph__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "accordion",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.openAccordion && $options.openAccordion(...args)))
  }, [
    _createElementVNode("img", {
      class: "accordion__image",
      src: $props.imgUrl
    }, null, 8 /* PROPS */, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["accordion__content__title", { isClicked: $data.isClicked }])
      }, _toDisplayString($props.title), 3 /* TEXT, CLASS */),
      _createVNode(_Transition, { name: "expand" }, {
        default: _withCtx(() => [
          ($data.isClicked)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("ol", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.paragraph, (text, index) => {
                    return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(`${index + 1}. ${text}`), 1 /* TEXT */))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}