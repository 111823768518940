<template>
  <div class="accordion" @click="openAccordion">
    <img class="accordion__image" :src="imgUrl" />
    <div class="accordion__content">
      <div class="accordion__content__title" v-bind:class="{ isClicked }">{{ title }}</div>
      <transition name="expand">
        <div v-if="isClicked" class="accordion__content__paragraph">
          <ol class="accordion__content__paragraph__list">
            <li v-for="(text, index) in paragraph" v-bind:key="index">
              {{ `${index + 1}. ${text}` }}
            </li>
          </ol>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Accordion',
  props: {
    imgUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    paragraph: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isClicked: false,
    };
  },
  methods: {
    openAccordion() {
      this.isClicked = !this.isClicked;
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  width: 262px;
  min-height: 225px;
  border-radius: 20px;
  padding: 0px;
  &__image {
    width: 262px;
    height: 175px;
  }

  &__content {
    padding: 15px 16px 15px 20px;
    &__title {
      color: var(--gray-gray-900, var(--opacity-black, #121214));
      /* H4 */
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      position: relative;
      letter-spacing: -0.5px;
      color: #121214;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 20px;
        width: 8px;
        height: 8px;
        margin: -5px -15px 1px 13px;
        border-top: 2px solid #121214;
        border-right: 2px solid #121214;
        vertical-align: middle;
        box-sizing: border-box;
        transform: rotate(135deg);
      }
    }
    &__paragraph {
      overflow: hidden;
      padding-top: 20px;
      &__list {
        color: #85808d;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
      }
    }
    .isClicked {
      &:after {
        transform: rotate(315deg);
      }
    }
  }

  .expand-enter-active,
  .expand-leave-active {
    transition: max-height 0.5s ease;
    height: auto;
    overflow: hidden;
  }

  .expand-enter-from,
  .expand-leave-to {
    height: 0;
    overflow: hidden;
  }
}
</style>
