import { render } from "./Accordion.vue?vue&type=template&id=c2d2277a&scoped=true"
import script from "./Accordion.vue?vue&type=script&lang=ts"
export * from "./Accordion.vue?vue&type=script&lang=ts"

import "./Accordion.vue?vue&type=style&index=0&id=c2d2277a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-c2d2277a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "c2d2277a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c2d2277a', script)) {
    api.reload('c2d2277a', script)
  }
  
  module.hot.accept("./Accordion.vue?vue&type=template&id=c2d2277a&scoped=true", () => {
    api.rerender('c2d2277a', render)
  })

}

script.__file = "src/page/promotion/component/Accordion.vue"

export default script